import Card from '../../../_NewComponents/Card/Card'
import s from './FeatureCards.module.scss'

export default function FeatureCards() {
  return (
    <div className={s.wrapper}>
        <div className={s.container}>
            <Card 
                img={"https://web.cdn.nexersys.com/assets/On_Demand_Training_Image_a08e149053.jpg"}
                title={"On-Demand Training"}
                body={"Nexersys’ motivating coaches guide you through fun, fast-paced and rewarding workout sessions. Choose a workout that matches your skill level and see how you stack up on the live leaderboard."}
            />
            <Card 
                img={"https://web.cdn.nexersys.com/assets/Perfect_for_any_Experience_Level_e40593ae8d.jpg"}
                title={"Train Your Body & Brain"}
                body={"Nexersys High Intensity Interval Training will deliver incredible results for your physique while teaching you new moves and challenging your brain."}
            />
            <Card 
                img={"https://web.cdn.nexersys.com/assets/Competition_d9a3b53d43.jpg"}
                title={"Community & Competitions"}
                body={"Train and compete with the Nexersys team and see your results real time while sharing your progress and achievements with your community."}
            />
        </div>
    </div>
  )
}