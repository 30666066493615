import Link from 'next/link';
import s from './Content.module.scss';

export default function WhiteGlove({price}) {
	return (
		<div className={s.whiteGlove} >
			<h1>N3E Professional Assembly</h1>
			<p>
				We are pleased to offer professional assembly of your Nexersys
				product in the Continental US. Nexersys is proud to offer white
				glove installation service for all N3 packages. After you take
				delivery, a skilled professional will assemble your N3 Elite
				unit and ensure that it’s working properly before leaving. This
				service also includes disposal of packaging material.
			</p>
			<h2>White Glove Assembly</h2>
			<h3 className={s.price}>{price}</h3>
		</div>
	);
}
