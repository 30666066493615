import s from './ProductCard.module.scss';
import Link from 'next/link';

export default function ProductCard({ data }) {
	function Logo() {
		if (data.tagLine) {
			return (
				<div className={s.tagLineLogo} >
					<img src={data.logo} alt="" className={s.logo} />
					<h2 className={s.tagLine}>{data.tagLine}</h2>
				</div>
			);
		} else {
			return <img src={data.logo} alt="" className={s.logo} />;
		}
	}

  

	return (
		<div className={s.wrapper}>
			<div className={s.top}>
				<div className={s.leftSection}>
          <Logo />
					<p>As low as</p>
					<h1 className={s.affirmPrice}>${data.affirmPrice}/mo</h1>
					<p>with <img src="https://web.cdn.nexersys.com/assets/blue_logo_transparent_bg_ec75b82bf6.png" alt="" className={s.affirmLogo}/></p>
					<p>Or ${data.price} in total</p>
				</div>
				<div className={s.rightSection}>
					<img src={data.photo} alt="" className={`${s.productImg} ${data.nonStandard? "":s.scaled}`} />
				</div>
			</div>
			<div className={s.bottom}>
				<div className={s.points} >
					{data.points.map((item, i) => {
						return <p key={i}><span>&#8226;</span> {item}</p>;
					})}
				</div>
				<Link href={data.link}><button className={s.btn}>Learn More</button></Link>
			</div>
		</div>
	);
}
