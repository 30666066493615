import React, { useEffect, useState } from 'react';
import API from '../../config/config';
import Carousel from 'react-multi-carousel';
import Link from 'next/link';



const CardCarouselInstagram = (props: any) => {
  const [checkdraggin, setcheckdraggin] = useState(false);

  const handledrag = (event: any) => {
    setcheckdraggin(true);
  };

  let timer: any = null;

  const handleMouseMove = () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      // do your things...
      // window.alert("hello")
      setcheckdraggin(false);
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  const handledragend = (event: any) => {
    setcheckdraggin(false);
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 8000, min: 990 },
      items: props.data.numberOfCards,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 990, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <div className="insta-container">
      <div className="container-fluid">
        <h1 className="insta-h1-title">{props.data.title}</h1>
        <Carousel
          swipeable
          draggable
          showDots
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite
          autoPlay={false}
          autoPlaySpeed={5000}
          arrows={false}
          keyBoardControl
          customTransition="transform 500ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          deviceType={props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {props.data.SocialMediaCards.map((card: any, i: number) => {
            return (
              <div className="insta-card-view" key={i}>
                <div
                  tabIndex={-1}
                  className="insta-post"
                  onMouseMove={event => {
                    if (window.innerWidth > 992) {
                      handledrag(event);
                    }
                  }}
                  onMouseDown={event => {
                    if (window.innerWidth > 992) {
                      handledragend(event);
                    }
                  }}
                >
                  <Link href={card.url} >
                    <a
                      draggable={false}
                      target="_blank"
                      rel="noreferrer"
                      className={checkdraggin ? 'disabled' : ''}
                    >
                      <img draggable={false} src={API.baseUrl + card.image.url}
                        aria-label={card.image.url}
                        alt={card.image.alternativeText} />
                    </a>
                  </Link>

                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default CardCarouselInstagram;
