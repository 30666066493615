import s from './Card.module.scss'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import icons from '../../../assets/icons/icons';

export default function Card({img, title, body, activated = false }) {
    const [hovered, setHovered] = useState(false)
    const [toggled, setToggled] = useState(activated)

    const isMobile = useMediaQuery({ query: `(max-width: 990px)` });



    const toggleHover = () => setHovered(!hovered)
    const plus =<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  fill='#FC5310' viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"/></svg>
    const minus = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill='#FC5310' viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-12v-2h12v2z"/></svg>
    
  return (
    <div 
    className={`${s.container} `} 
    onMouseEnter={isMobile? null:toggleHover}
    onMouseLeave={isMobile? null:toggleHover}
    >
        <div className={`${s.bgImage} ${hovered? s.hover:""}`} style={{backgroundImage: `url(${img})`}}>
          <div className={s.textBox} onClick={isMobile? toggleHover:null}>
              <div className={s.title}><h1>{title}</h1><span className={s.toggleIcon}>{hovered? minus:plus}</span></div>
              <p className={hovered? "hover":"hide"}>{body}</p>
          </div>
        </div>

    </div>
  )
}