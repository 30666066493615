import s from './TextCarousel.module.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useMediaQuery } from 'react-responsive';

export default function TextCarousel({title, background, carouselItems}) {
	const isMobile = useMediaQuery({ query: `(max-width: 990px)` });



	const responsive = {
		desktop: {
			breakpoint: { max: 20000, min: 990 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 990, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	return (
		<div
			className={s.container}
			style={{
				backgroundImage:
					`url(${background})`,
			}}
		>
			<div className={s.overlay}></div>
			<div className={s.overoverlay}></div>
			<h1 className={s.title}>{title}</h1>
			<Carousel
				centerMode={!isMobile}
				swipeable={false}
				draggable={false}
				showDots={true}
				responsive={responsive}
				ssr={true} // means to render carousel on server-side.
				infinite={true}
				autoPlay={true}
				autoPlaySpeed={10000}
				transitionDuration={1000}
				containerClass={s.carouselContainer}
				removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
				dotListClass={s.dots}
				itemClass={s.carouselItem}
			>
				{carouselItems.map((review, i) => {
					return (
						<div key={i}>
							{/* <h1>{review.title}</h1> */}
							<p><img src="https://prodnexersyswebsiteblob.blob.core.windows.net/assets/parenthesis_adfd5143fe.png" className={s.parenthesis}/>{review.body}</p>
							<h6>{review.name}</h6>
						</div>
					);
				})}
			</Carousel>
		</div>
	);
}
