import Badge from '../Badge/Badge'
import s from './FeatureBadges.module.scss'
import Satisfaction from './content/Satisfaction'
import WhiteGlove from './content/WhiteGlove'
import Warranty from './content/Warranty'

export default function FeatureBadges() {
  return (
    <div className={s.container}>
        <div className={s.iconsContainer}>
            <Badge 
            img={"https://prodnexersyswebsiteblob.blob.core.windows.net/assets/heroes_c8caa00cdf.png"} 
            text={'Heroes Discount'}
            linkText={'Learn More'}
            link={"/heroes-discount"}
            type={'link'}
            />
            <Badge 
            img={"https://prodnexersyswebsiteblob.blob.core.windows.net/assets/white_glove_f406be0bb3.png"}
            text={'White Glove Assembly'}
            linkText={'Learn More'}
            type={'modal'}
            modal={<WhiteGlove price={'$200 - $500'}/>}
            />
            <Badge 
            img={"https://prodnexersyswebsiteblob.blob.core.windows.net/assets/satisfaction_48f91a70a3.png"}
            text={'Satisfaction Guarantee'}
            linkText={'Learn More'}
            type={'modal'}
            modal={<Satisfaction/>}
            />
            <Badge 
            img={"https://prodnexersyswebsiteblob.blob.core.windows.net/assets/warranty_11d653a9ea.png"}
            text={'Warranty'}
            linkText={'Learn More'}
            type={'modal'}
            modal={<Warranty/>}
            />
        </div>
        <img src="https://prodnexersyswebsiteblob.blob.core.windows.net/assets/Hexagon_BLK_5_80c9098e64.svg" alt="" className={s.bottomHex}/>
    </div>
  )
}