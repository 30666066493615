import React from 'react';
import API from '../../config/config';

export default function TrainingSection(props: any) {
  const createMarkup = (markup: string) => {
    return {__html: markup}
  }

  return (
    <>
      <div className='training-section'>
        <div className='container-fluid'>
          <div className='training-container grid-container'>
              <div className='description-col'>
                <img className="flair-image-dt" src={API.baseUrl + props.data.desktopFlair.url} />
                <h2>{props.data.header}</h2>
                <div className="copy-container" dangerouslySetInnerHTML={createMarkup(props.data.copy)}/>
                <div className='icon-box'>
                  {props.data.icons.map((icon: any, i: number) => {
                      return (
                        <div className='icon-card-container' key={i}>
                          <div className="icon-container">
                            <img src={API.baseUrl + icon.image.url} alt="" />
                          </div>
                          <p>{icon.description}</p>
                        </div>
                      )
                      })
                  } 
                </div>
              </div>
              <div className='video-col'>
              <img className="flair-image-mb" src={API.baseUrl + props.data.mobileFlair.url} />
                <div className="video-wrapper">
                  <video autoPlay muted loop playsInline src={API.baseUrl + props.data.video.url}></video>
                </div>
              </div>
          </div>
        </div>
        <img src="https://prodnexersyswebsiteblob.blob.core.windows.net/assets/Hexagon_BLK_5_80c9098e64.svg" className='bottomLeftHex' />
      </div>
    </>
  )
  
}
