import React, { useEffect, useState } from 'react';
import {
	Card,
	Button,
	Row,
	Col,
	Container,
	Accordion,
	AccordionContext,
} from 'react-bootstrap';
import color from '../../assets/colors';
import icons from '../../assets/icons/icons';
import Link from 'next/link';
import ProductCard from '../_PageComponents/Homepage/ProductCard/ProductCard';

const ProductCardWidget = ({ data }) => {
	const windowSize = useWindowSize();
	function useWindowSize() {
		const [dimension, setDimensions] = useState({
			height: undefined,
			width: undefined,
		});

		useEffect(() => {
			const handleResize = () => {
				setDimensions({
					height: window.innerHeight,
					width: window.innerWidth,
				});
			};
			window.addEventListener('resize', handleResize);
			handleResize();
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}, []);
		return dimension;
	}
	const CustomToggle = ({ eventKey }: any) => {
		const currentEventKey = React.useContext(AccordionContext);
		const isCurrentEventKey = currentEventKey === eventKey;
		if (isCurrentEventKey) {
			return icons.negetive(color.$black, 'm-r-10 ');
		} else {
			return icons.plus(color.$black, 'm-r-10');
		}
	};

	const productInfo = [
		{
			logo: 'https://web.cdn.nexersys.com/assets/N3_Elite_dark_RGB_300x110_1_c848da8638.png',
			photo: 'https://web.cdn.nexersys.com/assets/N3_E_render_238aaf0825.png',
      name: "N3 Elite",
			link: '/product/n3-elite',
			affirmPrice: '87',
			price: '2295',
			points: [
				'Large library of guided boxing workouts',
				'3 anatomically accurate strike pads',
				'In the comfort of your own home',
			],
		},
		{
			logo: 'https://web.cdn.nexersys.com/assets/N3_C_Logo_Dark_1_728d8d7e9e.svg',
			photo: 'https://web.cdn.nexersys.com/assets/N3_C_render_731x1024_27270a7c9f.png',
      name: "N3 Commercial",
			link: '/product/n3-commercial',
			affirmPrice: '245',
			price: '6995',
			points: [
				'Adds leg pads for 5 total strike zones',
				'A complete kickboxing and MMA training experience',
				'Durable, commercial-grade materials',
			],
		},
		{
			logo: 'https://web.cdn.nexersys.com/assets/N3_Pro_dark_RGB_300x124_1_1_10714f3835.png',
			photo: 'https://web.cdn.nexersys.com/assets/N3_P_render_2cc703d455.png',
			link: '/product/n3-pro',
			affirmPrice: '29',
			price: '5495',
			points: [
				'Large library of guided boxing workouts',
				'3 anatomically accurate strike pads',
				'Durable, commercial-grade materials',
			],
		},
		{
			logo: 'https://web.cdn.nexersys.com/assets/n3_youth_logo_1_ba21de3139.svg',
			photo: 'https://web.cdn.nexersys.com/assets/N3_Y_render_de62e95b36.png',
			link: '/product/n3-youth',
			affirmPrice: '63',
			price: '1796',
			points: [
				'Gaming-inspired fitness to get kids moving',
				'Large library of guided boxing workouts',
				'3 anatomically accurate strike pads',
			],
		},
		{
			logo: 'https://web.cdn.nexersys.com/assets/Cross_Body_Trainer_logo_1_0c0cb7190e.png',
			photo: 'https://web.cdn.nexersys.com/assets/CBTH_slider_image_1_otvsnf4s3252bbfnvt5km2b8m5fewmkc4f68b0jlkw_e10c51cb29.png',
			tagLine: 'HOME',
			link: '/product/cross-body-trainer-home',
			affirmPrice: '27',
			price: '295',
			points: [
				'Interactive double-end bag',
				'Connects to our free training app',
				'Variety of full-body workouts',
			],
			nonStandard: true,
		},
		{
			logo: 'https://web.cdn.nexersys.com/assets/Cross_Body_Trainer_logo_1_0c0cb7190e.png',
			tagLine: 'PRO',
			photo: 'https://prodnexersyswebsiteblob.blob.core.windows.net/assets/cbt_P_transparent_1781321c98.png',
			link: '/product/cross-body-trainer-pro',
			affirmPrice: '21',
			price: '595',
			points: [
				'Interactive double-end bag',
				'Connects to our free training app',
				'Durable, commercial-grade materials',
			],
			nonStandard: true,
		},
	];

	return (
		<div className="app-product-card">
			<div
				className={`${
					windowSize.width > 951
						? `background-group-color-black`
						: `background-group-color`
				}`}
			>
				{windowSize.width > 981 && (
					<Container style={{ maxWidth: 1300 }}>
						<Row
							xl={3}
							md={2}
							sm={1}
							xs={1}
							className="app-product-cards"
						>
							{productInfo.map((item: any, i: number) => {
								return (
									<Col className="column-card" key={i}>
										<Card className="card-body-whole">
											<ProductCard data={item} />
										</Card>
									</Col>
								);
							})}
						</Row>
					</Container>
				)}
				{windowSize.width <= 981 && (
					<>
						<h2 className="view-prod-accordian">
							View Our Products{' '}
						</h2>
						<div className="app-product-accordian">
							<Accordion defaultActiveKey="1">
								{data &&
									data?.productInfo &&
									data.productInfo?.length > 0 &&
									data.productInfo?.map(
										(cardItems: any, i: number) => {
											return (
												cardItems && (
													<Card
														key={i}
														style={{
															borderLeft: 'none',
														}}
													>
														<Accordion.Toggle
															eventKey={`${
																i + 1
															}`}
															as={Card.Header}
															className="capitalize-name bg-color-accordion"
														>
															<CustomToggle
																eventKey={`${
																	i + 1
																}`}
															/>
															{cardItems.name}
														</Accordion.Toggle>
														<Accordion.Collapse
															eventKey={`${
																i + 1
															}`}
														>
															<React.Fragment>
																{cardItems && (
																	<div className="app-img-width">
																		<img
																			src={
																				cardItems
																					.image
																					?.url
																			}
																			alt={
																				''
																			}
																		/>
																	</div>
																)}
																<div className="app-prod-details">
																	<h3 className="app-prod-title capitalize-name">
																		{
																			cardItems.name
																		}
																	</h3>
																	<div className="carditem-price mobile-price">
																		<p>As low as
																		</p>
																		<h4>
																			$
																			{
																				cardItems.affirmPrice
																			}
																			/mo
																		</h4>
																		<p>
																			with{' '}
																			<img
																				className="affirm-logo affirm-mb"
																				src="https://web.cdn.nexersys.com/assets/Affirm_logo_white_55bee80414.svg"
																				alt="affirm logo"
																			/>
																		</p>
                                    <p>Or ${cardItems.price} in total</p>
																	</div>
																	<h6 className="app-prod-desc">
																		{
																			cardItems.description
																		}
																	</h6>
																	<div
																		style={{
																			marginTop: 35,
																			marginBottom: 25,
																		}}
																	>
																		<Link
																			href={`${cardItems.buttonRedirectUrl}`}
																		>
																			<a
																				draggable={
																					false
																				}
																				className="button-card-product"
																			>
																				learn
																				more
																			</a>
																		</Link>
																	</div>
																</div>
															</React.Fragment>
														</Accordion.Collapse>
													</Card>
												)
											);
										}
									)}
							</Accordion>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ProductCardWidget;
