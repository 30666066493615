import icons from '../../assets/icons/icons';
import color from '../../assets/colors';
import { useEffect } from 'react';

const VideoWigets = ({ onCloseHandler, videoUrl, closePosition }: any) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <div className="widget-player">
      <div className="widgetplayer-overlay" onClick={onCloseHandler} />
      {!closePosition && <span onClick={onCloseHandler}>{icons.close(color.$white, 'fa-2x')}</span>}
      <div className="widget-video">
        <video autoPlay controls src={videoUrl} />
        {closePosition && <span onClick={onCloseHandler}>{icons.close(color.$white, 'fa-2x')}</span>}
      </div>
    </div>
  );
};

export default VideoWigets;
