import React from 'react'

export default function PromotionalBanner({data, display = false}) {
  if (display) {
    return (
      <div className='promo-banner'>
          <h3>{data}</h3>
      </div>
    )
  }

  return null
}
