import React, { useEffect, useState } from "react";
import VideoWidget from "../components/VideoImageSection/VideoImageSectionGroup";
import TrainingSection from "../components/TrainingSection/TrainingSection";
import CardCarouselInstagram from "../components/CardCarouselInstagram/CardCarouselInstagram";
import FooterComp from "../components/Footer/Footer";
import HeaderComp from "../components/Header/Header";
import config from "../config/config";
import ProductCardWidget from "../components/ProductCardWidget/ProductCardWidget";
import PromotionalBanner from "../components/PromotionalBanner/PromotionalBanner";
import TestimonialRatings from "../components/TestimonialRatings/TestimonialRatings";
import SetHeaders from "../config/SetHeaders";
import seo from "../config/seo";
import axios from "axios";
import Head from "next/head";
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic';
import FeatureCards from '../components/_PageComponents/Homepage/FeatureCards/FeatureCards'
import FeatureBadges from '../components/_NewComponents/FeatureBadges/FeatureBadges'
import TestimonialBG from '../components/_PageComponents/Homepage/TestimonialBG/TestimonialBG'

const HeroVideo = dynamic(() => import('../components/_NewComponents/HeroVideo/HeroVideo'), {
	ssr: false
})


const HomePageContainer = ({ Header, Body, Footer, response }: any) => {
  const windowSize = useWindowSize();
  const router = useRouter()
  useEffect(() => {
    const { query : { post_type }} = router
    if(post_type == 'wc_product_tab' ){
        router.push('/no-warranty-application')
    }
  });
  function useWindowSize() {
    const [dimension, setDimensions] = useState({
      height: undefined,
      width: undefined,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return dimension;
  }


  return (
    <React.Fragment>
      {response && !response.Seo && (
        <Head>
          <title>{seo.home}</title>
        </Head>
      )}
      {response && <SetHeaders HEADERS={response} />}
      {Header && <HeaderComp HeaderValue={Header} />}
      <div className="app-body">
        <PromotionalBanner data={"yes"} />
        <HeroVideo />
        <TestimonialRatings data={Body[2]} landing={false} />
        <FeatureCards />
        <VideoWidget data={Body[4]} />
        <ProductCardWidget data={Body[5]} />
        <TrainingSection data={Body[8]} />
        <TestimonialBG/>
        <CardCarouselInstagram data={Body[7]} />
        <FeatureBadges />
      </div>
      
      {Footer && <FooterComp data={Footer} />}
    </React.Fragment>
  );
};

export async function getStaticProps() {
  const { data } = await axios.get(config.endpoints.homepage); 
  return {
    props: {
      Header: data.Header,
      Body: data.Body,
      Footer: data.Footer.Footer,
      response: data,
    },
    revalidate: 3600
  
  };
}

export default HomePageContainer;
