import Link from 'next/link';
import s from './Content.module.scss';

export default function Satisfaction() {
	return (
		<>
			<div className={s.header}>
				<div>
					<img src="https://devcms.nexersys.com/uploads/Sat_G_1a402da7d4.svg"></img>
				</div>
				<h2>N3 Elite Satisfaction Guarantee</h2>
			</div>
			<div className={s.satisfactionItems}>
				<ol>
					<li>
						<h6>
							If you are not satisfied with your Nexersys N3
							Elite, N3 Youth, N3 Pro or N3 Commercial, you can
							request a return within 30 days after your delivery.
						</h6>
					</li>
					<li>
						<h6>
							Nexersys’ guarantee doesn’t cover ordinary wear and
							tear or damage caused by improper use or accidents
							subject to the terms of our Limited Warranty for
							each model. Learn more on our website{' '}
							<Link href="/warranty/">
								https://nexersys.com/warranty/
							</Link>
						</h6>
					</li>
					<li>
						<h6>
							The Nexersys unit must be returned undamaged in its
							original packaging with all parts included. If your
							Nexersys is returned in damaged condition, with
							missing parts, or otherwise has signs of abuse,
							Nexersys reserves the right to refuse a refund or to
							charge additional restocking fees, at Nexersys’ sole
							discretion.
						</h6>
					</li>
					<li>
						<h6>
							Upon receipt of the unit, we will issue a refund of
							the purchase price paid, excluding any Shipping or
							Assembly fees.
						</h6>
					</li>
					<li>
						<h6>
							To initiate a return, please contact us at{' '}
							<span>1-512-782-2940</span> or{' '}
							<a href="mailto:support@nexersys.com">
								support@nexersys.com
							</a>
						</h6>
					</li>
					<li>
						<h6>
							The following Nexersys accessories may not be
							returned or exchanged if they have been worn or
							used:
						</h6>
						<ol>
							<li>
								<h6>Hand Wraps</h6>
							</li>
							<li>
								<h6>Gloves</h6>
							</li>
							<li>
								<h6>Sports Towel</h6>
							</li>
						</ol>
					</li>
					<li>
						<h6>
							Return request must be made in writing or email and
							received by Nexersys within 30 days after the
							original delivery date. Returned units must arrive
							at the designated Nexersys facility within 45 days
							after the original delivery date. Nexersys is not
							responsible for packages lost in transit without
							proof of tracking. Refunds for purchases will be
							credited back to the original payment method only.
						</h6>
					</li>
				</ol>
			</div>
		</>
	);
}
