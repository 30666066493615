import Link from 'next/link';
import s from './Badge.module.scss';
import { useState } from 'react';
import Modal from '../Modal/Modal'

export default function Icon({
	img = 'https://prodnexersyswebsiteblob.blob.core.windows.net/assets/placeholder_baa62d6434.png',
	text = 'Icon Text',
	linkText = '',
	link = '',
	modal = '',
	type = 'modal'
}) {
	


	function BadgeLink () {
		switch (type) {
			case 'link':
				return (<Link href={link} ><a className={s.link}>{linkText}</a></Link>)
			case 'modal':
				const [isOpen, setIsOpen] = useState(false)
				const handleToggle = () => {
					setIsOpen(!isOpen)
				}

				return (
					<>
						<a className={s.link} onClick={handleToggle}>{linkText}</a>
						{isOpen && 
						<Modal handler={handleToggle}>
							{modal}
						</Modal>}
					</>
				)
			case 'none':
				return null
			default:
				console.error('An unrecognized value was passed to "type" prop in badge component.')
				return null
		}
	}



		



	return (
		<div className={s.iconContainer}>
			{/* <img src={img} alt="" /> */}
            <div className={s.image}  style={{backgroundImage: `url(${img})`}}/>
			<h1 className={s.text}>{text}</h1>
			{/* {linkText !== '' && <BadgeLink />} */}
			<BadgeLink />
		</div>
	);
}
