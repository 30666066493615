import s from './Modal.module.scss'
import icons from 'assets/icons/icons'

export default function Modal({handler, children}) {
  return (
    <div className={s.modalContainer}>
        <div className={s.darkBG} onClick={handler}></div>
        <div className={s.content}>
            <div className={s.close} onClick={handler}>{icons.close('#000', '')}</div>
            {children}
        </div>

    </div>
  )
}