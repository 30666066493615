import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VideoWidgetItem from './VideoImageItem';
import API from '../../config/config';


const VideoImageSectionGroup = (props: any) => {
  return (
    <div
      className="videoimage-container"
      style={{ backgroundImage: 'url(' + API.baseUrl + props.data.mobileBackgroundImage.url + ')' }}
    >
      <Container className="video-image-container">
        <Row lg="4" xs="1" md="1" className="center-ing">
          {props.data.VideoPlayerCardWidget.map((item: any, i: number) => {
            return (
              <Col className="VideoWidgetItem" key={i}>
                <VideoWidgetItem item={item} />
              </Col>
            );
          })}
        </Row>
      </Container>
      <img src='https://prodnexersyswebsiteblob.blob.core.windows.net/assets/Hexagon_BLK_5_80c9098e64.svg' className="hexBottomLeft"/>
    </div>
  );
};

export default VideoImageSectionGroup;
