const seo = {
	blog: 'Blog - Nexersys',
	training: 'Training Videos and Tutorials - Nexersys',
	testimonial: 'Testimonials and Customer Reviews - Nexersys',
	termsOfUse: 'Terms of Use - Nexersys',
	privacyPolicy: 'Privacy Policy - Nexersys',
	sportTowel: 'Seat Cover and Sports Towel - An Oversized, Microfiber Towel - Nexersys',
	parts: 'Parts - Hardware and Software - Nexersys and Cross Body Trainer',
	accessories: 'Accessories - Nexersys',
	checkout: 'Checkout - Nexersys',
	connectConfirmation: 'Connect Confirmation - Nexersys',
	connect: 'Connect and Customer Service - Nexersys',
	home: 'Nexersys: Hard Body. Sharp Mind. - No Experience Required - XFit, Inc.',
	military: 'Military & First Responder Discount - Nexersys',
	manuals: 'User Manuals - Nexersys',
	landing: 'N3 Elite - The Personal Boxing Trainer for Your Home',
	faq: 'Frequently Asked Questions - Nexersys',
	confirmCheckout: 'Order Confirmed - Nexersys',
	n3Bundle: 'N3 Elite Bundles - Take Your Fitness to the Next Level!',
	pageNotFound: 'Page Not Found - Nexersys',
	search: 'You searched for',
	end: '- Nexersys',
	returnPolicy: 'Return Policy - Nexersys',
	accessibility: 'Accessibility Statement - Nexersys',
	tshirtform: 'T-Shirt Form - Nexersys',
	heroesdiscount: 'Heroes Discount - Nexersys',
	leadForm: 'N3 Lead Form Page - Nexersys',
	customerServiceServey: 'Customer Service Survey - Nexersys',
	customerServey1: 'Customer Survey #1 - Nexersys',
	customerServey2: 'Customer Survey #2 - Nexersys',
	customerServey3: 'Customer Survey #3 - Nexersys',
	cbtCustomerServey3:'Cross Body Trainer Customer Survey #1 - Nexersys',
	leaveReviewForm:'Leave a Review - Nexersys',
	returnRequest: 'Return Request - Nexersys',
	educationDiscount:'Educator Discount - Nexersys',
	noWarrantyApplication: 'Warranty Information - Nexersys',
};

export default seo;
