import React from "react";
import ProductTestimonialsWidget from "../ProductTestimonialsWidget/ProductTestimonialsWidget";
import RatingStars from "../RatingStars/RatingStarts";
import Slider from "react-slick";

const TestimonialRatings = (props: any) => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    customPaging: function (i) {
      return (
        <div
          className={`${
            props.landing ? `slider-circle-landing` : `slider-circle`
          }`}
        />
      );
    },
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    dotsClass: "slick-dots slick-thumb",
  };

  return (
    <div
      className={`${
        props.landing
          ? `testimonial-review-slider-container-landing`
          : `testimonial-review-slider-container testimonial-review-slider-spacing`
      }`}
    >
      {!props.landing && <div>
        <img className="hex-left-top" src={props.data?.icon?.url} />
      </div>}
      {!props.landing && <div>
        <img
          className="hex-right-bottom"
          src={props.data?.icon?.url}
        />
      </div>}
      <div className={`${props.landing && `testiminial-wrapper`}`}>
      <Slider {...settings}>
        {props.data &&
          props.data.ReviewDetails &&
          props.data.ReviewDetails?.length > 0 &&
          props.data.ReviewDetails?.map((item: any, i: any) => (
            <div
              key={i}
              className={`review-star-container ${
                props.landing && `review-star-container-landing`
              }`}
            >
              {!props.landing && (
                <div>
                  <RatingStars
                    ratingValue={item.Rating}
                    className="transpertant-rating"
                  />
                </div>
              )}
              {props.landing && (
                <div className="loves-nexersys">
                  THE COMMUNITY <span className="landing-accent">LOVES</span>{" "}
                  NEXERSYS
                </div>
              )}
              {props.landing ? (
                <div
                  className={`${props.landing && `testimonial-info-container`}`}
                >
                  {i === 0 ? (
                    <ProductTestimonialsWidget
                      data={props.data}
                      landing={true}
                    />
                  ) : (
                    <>
                      <div
                        className={`${
                          props.landing
                            ? `review-testimonial-landing-title landing-testimonial2 landing-color`
                            : `review-testimonial-title add-max-width`
                        }`}
                      >
                        {item.Review}
                        <div className="landing-testimonial-quote">“</div>
                      </div>
                      <div className="reviewer-details">
                        <span style={{ color: props.landing && "#fc5310" }}>
                          {item.CustomerName}
                        </span>{" "}
                        <span className={`${props.landing && `landing-color`}`}>
                          {item.CustomerLocation}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div className="review-testimonial-title">{item.Review}</div>
                  <div className="reviewer-details">
                    <span>{item.CustomerName}</span>{" "}
                    <span>{item.CustomerLocation}</span>
                  </div>
                </>
              )}
            </div>
          ))}
      </Slider>
      </div>
    </div>
  );
};

export default TestimonialRatings;
