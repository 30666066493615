import TextCarousel from 'components/_NewComponents/TextCarousel/TextCarousel';

export default function TestimonialBG() {
	const carouselItems = [
		{
			title: '1',
			body: "Our entire family loves the Nexersys machine. Not only a great workout, but a good stress reliever after a rough day. Our teenager daughter has found a love for boxing she didn’t know she had - not to mention a little self-defense training.", 
      name: 'Anthony W. Care Coral, FL',
		},
		{
			title: '2',
			body: 'Now I do all of my boxing workouts at home and have saved significant time during my busy week.  I highly recommend this product to anyone who is looking to achieve a higher level of fitness.',
			name: 'Erryn M. Fincastle, VA',
		},
		{
			title: '3',
			body: 'I never thought that I would find myself not only easily completing my required cardio on a weekly basis but looking forward to doing it!',
			name: 'Jordan D. Denver, CO',
		},
		{
			title: 'Title',
			body: "I am 64 yrs old and want to keep myself fit and trim. I find my Nexersys is great for getting myself in the best physical condition I can get and staying there.",
			name: 'Kenneth S. Pinehurst, TX',
		},
		{
			title: 'Title',
			body: 'The N3 is a great product that creates an entertaining way to get an intense workout. I especially like that this system does not require a monthly subscription.',
			name: 'Mitch B. Richmond, VA',
		},
		{
			title: 'Title',
			body: 'I have had my nexersys for over a year and it has been the best workout for me! Love it and will continue to use! Great workout, challenging and great for stress relief and the body!',
			name: 'Rina F. Manchester',
		},
		{
			title: 'Title',
			body: 'It’s more than beating up a bag. This machine test your mind. Excellent workout and excellent mind focus. Worth every penny.',
			name: 'Brande D. Fairview, PA',
		},
		{
			title: 'Title',
			body: 'Talk about stress release! It\'s amazing to me that I can have such a great time while getting a tremendous workout. Highly recommend for anyone looking to restart their workout program or take it to the next level.',
			name: 'Lori B. Oregon, WI',
		},
	];

	return (
		<TextCarousel
			title={'Take It From Our Athletes'}
			background={
				'https://prodnexersyswebsiteblob.blob.core.windows.net/assets/ratings_bg_a33e5f36ae.jpg'
			}
			carouselItems={carouselItems}
		/>
	);
}
