import React, { useState } from 'react';
import WidgetPlayer from '../WidgetPlayer/WidgetPlayer';
import API from '../../config/config';

const VideoImageItem = (props: any) => {
  const [videoUrl] = useState(API.baseUrl + props.item.video.url);
  const [isclosed, setisClosed] = useState(true);

  const onTogglerHandler = () => {
    setisClosed(!isclosed);
  };

  return (
    <div onClick={onTogglerHandler}>
      <img
        src={props.item.external ? props.item.url : API.baseUrl + props.item.thumbnail.url}
        alt={props.item.thumbnail.hash}
        className="VideoWidgetItemImg"
        
      />
      {!isclosed ? <WidgetPlayer videoUrl={videoUrl} onCloseHandler={onTogglerHandler} /> : null}
    </div>
  );
};

export default VideoImageItem;
